/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-toastr/toastr';

html,
body {
    height: 100%;
}



@font-face {
    font-family: 'Montserrat';
    src: url('./assets/font/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.mat-elevation-z8 {
    box-shadow: none;
}

.custom-font,
.mat-typography {
    margin: 0;
    font-family: Montserrat;
}

.side_bar_common_css {
    display: flex;
}

.mat-table {
    font-family: Montserrat;
}

.mat-header-cell {
    color: #828382;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background: rgba(244, 244, 244, 0.75);
    box-shadow: 0px 2px 3px 0px rgba(204, 204, 204, 0.42);
    backdrop-filter: blur(34.5px);
    position: sticky;
    top: 0;
}

.mat-cell {
    color: #484A46;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}
img{
    cursor: pointer;
}
.mat-dialog-container{
    padding: 0;
    background: transparent;
    box-shadow: none;
}

/* input number css  */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}



/* Multi Select AutoComplete */
.mat-autocomplete-panel::-webkit-scrollbar {
    width: 8px;
    height: 50px;
    border-radius: 4px;
    opacity: 0.6;
}

/* Track  */
.mat-autocomplete-panel::-webkit-scrollbar-track {
    background: #EAEAEA;
    width: 8px;
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.09) inset;
}

/* Handle */
.mat-autocomplete-panel::-webkit-scrollbar-thumb {
    background: #6C913D;
    background: #6C913D 0% 0% no-repeat padding-box;
    width: 8px;
    height: 100px;
    border-radius: 4px;
    opacity: 0.6;
}




/* Apex Chart Css */
::ng-deep .apexcharts-legend{
    display: flex !important;
    justify-content: center !important;
}
::ng-deep .apexcharts-legend-series{
    display: flex;
    align-items: center;
}




/* ng select  */
::ng-deep.ng-select-container {
    border: none !important;
    padding: 0 !important;
    background-color: #F3F3F3 !important;
    min-height: 48px !important;
}

::ng-deep.cdk-overlay-pane{
    min-width: 80vh !important;
    overflow: auto !important;
}
.notif{
    width: 100%;
}
.notificationBar{
    min-height: 4rem;
    max-height: 4rem;
    z-index: 1;
}
.shwoNotififications{
    right: 30px;
    position: absolute;
    width: 70vw;
    min-height: 20vh;
    background-color: #F3F3F3;
    border: 1px solid #6C913D;
    padding: 10px 15px;
    border-radius: 12px;
    max-height: 30rem;
    top: 4rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
.notificationContent b{
    font-weight: 900 !important;
    color: #6C913D;
    font-size: 16px;
}
.notificationContent p{
    font-weight: 900 !important;
    display: inline-block;
    color: #7893ec;
    font-size: 14px;
}
.notificationContent strong{
    font-size: 14px;
    font-weight: 900; /* Black - Boldest */
    -webkit-text-stroke: 0.25px black; 
}
.notificationHeader{
    font-weight: 800;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}
.notif{
    padding: 10px 5px;
    /* border-radius: 5px; */
    border-bottom:  1px solid #6C913D;
}
.notif:hover{
    cursor: pointer;
    box-shadow: 0px 5px 10px 0px rgba(108, 145, 61, 0.42)
}